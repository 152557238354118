import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import type { AlertProps } from '@material-ui/lab';
import type { FC } from 'react';
import * as React from 'react';
import { Alert, makeStyles } from '../../utils/material';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '328px',
    },
  },
}));

type Props = AlertProps & {
  msg: string;
  withoutIcon?: boolean;
};

const Snackbar: FC<Props> = ({
  msg,
  withoutIcon = false,
  variant = 'filled',
  severity = 'success',
  ...rest
}) => {
  const defaultProps = { variant, severity };
  const classes = useStyles();
  let icon: React.ReactElement | false = false;
  if (!withoutIcon) {
    switch (severity) {
      case 'error':
        icon = <ErrorIcon data-testid="icon-error" fontSize="inherit" />;
        break;
      case 'info':
        icon = <InfoIcon data-testid="icon-info" fontSize="inherit" />;
        break;
      case 'warning':
        icon = <ReportProblemIcon data-testid="icon-warning" fontSize="inherit" />;
        break;
      default:
        icon = <CheckCircleIcon data-testid="icon-success" fontSize="inherit" />;
        break;
    }
  }

  return (
    <Alert
      data-testid="app-snackbar"
      className={classes.container}
      icon={icon}
      {...defaultProps}
      {...rest}
    >
      {msg}
    </Alert>
  );
};

export default Snackbar;
