import { useFormikContext } from 'formik';
import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import { FormControlLabel, Radio, RadioGroup } from '../../../utils/material';
import { handleError } from '../../../utils/utils';
import ErrorMessage from './ErrorMessage';

interface Props {
  question: SurveyQuestion;
}

const YesNoQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    values,
    setFieldValue,
    setSubmitting: setFormikSubmitting,
  } = useFormikContext<SurveyValues>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue(String(question.id), value);

    setSubmitting(true);
    setFormikSubmitting(true);
    SurveysService.postAnswer(question.id, value === 'YES')
      .then(updateAnswer)
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: `There was an error saving answer for question ${question.rank}`,
        });
        setFieldValue(String(question.id), undefined); // TODO: Old value instead of undefined string if already saved
      })
      .finally(() => {
        setSubmitting(false);
        setFormikSubmitting(false);
      });
  };

  return (
    <>
      <RadioGroup value={values[question.id] || ''} onChange={handleChange}>
        <FormControlLabel
          value="YES"
          control={<Radio color="primary" disabled={submitting} />}
          label="Yes"
          disabled={submitting}
        />
        <FormControlLabel
          value="NO"
          control={<Radio color="primary" disabled={submitting} />}
          label="No"
          disabled={submitting}
        />
      </RadioGroup>
      <ErrorMessage name={String(question.id)} />
    </>
  );
};

export default YesNoQuestion;
