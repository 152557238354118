import type { BrowserOptions } from '@sentry/browser';
import { captureException, init } from '@sentry/browser';

const options: BrowserOptions = {
  dsn: 'https://f990ff615fe94a79bb201f13dae329a7@o372675.ingest.sentry.io/6021632',
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const environment = window.location.href.includes('stage') ? 'stage' : 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release) {
      init({
        ...options,
        ...config,
        environment,
        release,
      });
    }
  },
  trackError: captureException,
};
