import type { Moment } from 'moment';
import moment from 'moment';
import { SentryService } from '../services/sentry';
import { TABLE_DATE_FORMAT } from './dates';
import message from './toast';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any, defaultMessage?: string) => {
  const msg = error.displayMessage || error.message;

  if (defaultMessage) {
    return msg || defaultMessage;
  }

  return msg;
};

export const isSameDay = (dateTime1: string, dateTime2: string): boolean => {
  return moment.utc(dateTime1).isSame(moment.utc(dateTime2), 'day');
};

export async function downloadImage(imageSrc: string, filename: string) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return link;
}
export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const enumToLabel = (value: string) =>
  value
    .split('_')
    .map((word) => capitalize(word.toLocaleLowerCase()))
    .join(' ');

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (
  value?: string | number,
  options: { accountingStyle?: boolean; noEmptyDecimals?: boolean } = {
    accountingStyle: false,
    noEmptyDecimals: false,
  },
) => {
  if (value == null) {
    return '';
  }

  const numericValue = Number(value);

  if (options.accountingStyle && numericValue < 0) {
    const formatted = currencyFormatter.format(-numericValue);
    return `(${options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted})`;
  }

  const formatted = currencyFormatter.format(numericValue);
  return options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted;
};

export const formatAge = (placedInServiceDate: string) => {
  const duration = moment.duration(moment().diff(placedInServiceDate));

  return [
    duration.years() > 0 ? `${duration.years()} y` : null,
    duration.months() > 0 ? `${duration.months()} m` : null,
    duration.days() > 0 ? `${duration.days()} d` : null,
  ]
    .filter((x) => x)
    .join(', ');
};

/**
 * Use this function instead of 'lodash/chain'
 * @see https://skyboxcapital.atlassian.net/browse/PN-4485
 * @see https://github.com/lodash/lodash/issues/4712
 */
export const groupArrayBy = <T, KeyType = string>(
  array: T[],
  key: keyof T,
  sortFn?: (a: T, b: T) => number,
) => {
  const result = new Map<unknown, T[]>();

  array.forEach((item) => {
    const value = item[key];
    if (!result.has(value)) {
      result.set(value, [item]);
    } else {
      result.get(value)!.push(item);
    }
  });

  return [...result].map(([k, value]) => ({
    key: k as KeyType,
    value: sortFn ? value.sort(sortFn) : value,
  }));
};

export const mapDateRange = (value: [Moment | undefined, Moment | undefined]) => {
  if (Array.isArray(value)) {
    const val0 = value[0]?.format?.(TABLE_DATE_FORMAT) ?? '?';
    const val1 = value[1]?.format?.(TABLE_DATE_FORMAT) ?? '?';

    return `${val0} - ${val1}`;
  }

  return undefined;
};

export const prettierLabel = (label: string) => {
  return label
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

type HandleErrorOptions = {
  displayToast?: boolean;
  toastMessage?: string;
  toastFallbackMessage?: string;
  rethrowError?: boolean;
  sendToSentry?: boolean;
};
export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  {
    displayToast = true,
    toastMessage,
    toastFallbackMessage = 'Unexpected Error',
    rethrowError = false,
    sendToSentry = true,
  }: HandleErrorOptions,
) => {
  if (displayToast) {
    const errorMessage = toastMessage ?? getErrorMessage(error, toastFallbackMessage);
    message.error(errorMessage);
  }
  if (sendToSentry) {
    SentryService.trackError(error);
  }

  if (rethrowError) {
    throw error;
  }
};
