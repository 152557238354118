import { useFormikContext } from 'formik';
import type { FC } from 'react';
import type { SurveyValues } from '../../../domain/survey';
import { Box, Typography } from '../../../utils/material';

interface Props {
  name: string | number;
}

const ErrorMessage: FC<Props> = ({ name }) => {
  const { errors, touched } = useFormikContext<SurveyValues>();

  const error = errors[name];
  const isTouched = touched[name];

  if (!isTouched || !error) {
    return null;
  }

  return (
    <Box mt={1}>
      <Typography variant="caption" color="error">
        {error}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
