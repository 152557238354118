import moment from 'moment';

export const SERVICE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const TABLE_DATE_FORMAT = 'MM/DD/YYYY';
export const TABLE_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A';

export const formatDate = (date: string): string =>
  moment(date, SERVICE_FORMAT).format(TABLE_DATE_FORMAT);

export const formatDateTime = (date: string): string =>
  moment(date, SERVICE_FORMAT).format(TABLE_DATE_TIME_FORMAT);

export const formatDateWithServiceFormat = (date: string): string =>
  moment(date).format(TABLE_DATE_FORMAT);
