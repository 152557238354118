import { useFormikContext } from 'formik';
import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '../../../utils/material';
import { handleError } from '../../../utils/utils';
import ErrorMessage from './ErrorMessage';

interface Props {
  question: SurveyQuestion;
}

const useStyles = makeStyles<null, { isMobile: boolean }>({
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 14,
  },
  optionsContainer: {
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
    justifyContent: 'space-between',
  },
});

const OptionsQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    values,
    setFieldValue,
    setSubmitting: setFormikSubmitting,
  } = useFormikContext<SurveyValues>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue(String(question.id), value);

    setSubmitting(true);
    setFormikSubmitting(true);
    SurveysService.postAnswer(question.id, value)
      .then(updateAnswer)
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: `There was an error saving answer for question ${question.rank}`,
        });
        setFieldValue(String(question.id), undefined);
      })
      .finally(() => {
        setSubmitting(false);
        setFormikSubmitting(false);
      });
  };

  const value = values[question.id];

  return (
    <>
      <RadioGroup value={value} onChange={handleChange} className={classes.optionsContainer}>
        {question.options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" disabled={submitting} />}
            label={option.displayName}
            disabled={submitting}
          />
        ))}
      </RadioGroup>
      <ErrorMessage name={String(question.id)} />
    </>
  );
};

export default OptionsQuestion;
