import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useState } from 'react';
import DropFile from '../../../components/common/DropFile';
import {
  Box,
  Button,
  DeleteIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '../../../utils/material';
import message from '../../../utils/toast';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSuccess: (file: File, description: string) => void;
}

const AddFileModal: FC<Props> = ({ open, onCancel, onSuccess }) => {
  const [file, setFile] = useState<File>();
  const [description, setDescription] = useState('');

  const handleSuccess = () => {
    if (isEmpty(description)) {
      message.warning('File description is required.');
      return;
    }
    onSuccess(file!, description);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Add a file</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
          File{' '}
          <Typography component="span" color="error">
            *
          </Typography>
        </Typography>
        {file ? (
          <Box>
            <Typography color="primary" variant="subtitle1" component="span">
              {file.name}
            </Typography>
            <IconButton
              size="small"
              style={{ color: 'red', marginLeft: 8 }}
              onClick={() => setFile(undefined)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <DropFile
            accept="image/png, image/gif, image/jpeg"
            multiple={false}
            onDrop={(files) => setFile(files[0])}
          />
        )}
        <TextField
          style={{ marginTop: 20 }}
          size="small"
          label="Description"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button color="primary" variant="contained" disabled={!file} onClick={handleSuccess}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFileModal;
