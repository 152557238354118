import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import { Box, Button, Divider, Typography } from '../../../utils/material';
import { getQuestionsInitialValues, getQuestionsValidationSchema } from '../../../utils/survey';
import message from '../../../utils/toast';
import { handleError } from '../../../utils/utils';
import SectionHeader from './SectionHeader';
import SurveyQuestion from './SurveyQuestion';

const SurveyForm: FC = () => {
  const { survey, description, storedAnswers, allQuestions } = useContext(SurveyContext);
  const [submitted, setSubmitted] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [sectionsAnswers, setSectionsAnswers] = useState<Record<number, SurveyValues>>({});
  const [submittingForm, setSubmittingForm] = useState(false);
  const [isFormikSubmitting, setIsFormikSubmitting] = useState(false);
  // Set to `true` when the submit button is clicked
  const [mustSubmit, setMustSubmit] = useState(false);

  const currentQuestions = survey.sections[currentSectionIndex].questions || [];

  const isFirstSection = currentSectionIndex === 0;
  const isLastSection = currentSectionIndex + 1 === survey.sections.length;

  const initialValues = useMemo(() => {
    const savedAnswers = sectionsAnswers[currentSectionIndex];

    return getQuestionsInitialValues(allQuestions, savedAnswers, storedAnswers);
  }, [allQuestions, sectionsAnswers, storedAnswers, currentSectionIndex]);

  const validationSchema = useMemo(() => {
    return getQuestionsValidationSchema(currentQuestions);
  }, [currentQuestions]);

  const sortedQuestions = useMemo(() => {
    return currentQuestions.sort((q1, q2) => q1.rank - q2.rank);
  }, [currentQuestions]);

  useEffect(() => {
    // Submit only if the submit button was clicked and there's not answer being saved
    if (mustSubmit && !isFormikSubmitting) {
      setMustSubmit(false);
      setSubmittingForm(true);

      SurveysService.submit()
        .then((res) => {
          setSubmitted(true);

          if (res?.redirectTo) {
            window.location.replace(res.redirectTo);
          }
        })
        .catch((err) => {
          handleError(err, {
            displayToast: true,
            rethrowError: false,
            sendToSentry: true,
            toastMessage: 'There was an error submitting the survey',
          });
          setSubmittingForm(false);
        });
    }
  }, [isFormikSubmitting, mustSubmit]);

  const handleSectionSubmit = (
    sectionAnswers: SurveyValues,
    helpers: FormikHelpers<SurveyValues>,
  ) => {
    setSectionsAnswers({
      ...sectionsAnswers,
      [currentSectionIndex]: sectionAnswers,
    });

    if (isLastSection) {
      setMustSubmit(true);
    } else {
      setCurrentSectionIndex(currentSectionIndex + 1);
      helpers.setSubmitting(false);
      const nextSection = survey.sections[currentSectionIndex + 1];

      nextSection.questions.forEach((q) => {
        helpers.setFieldTouched(String(q.id), false);
      });
    }
  };

  useEffect(() => {
    // Scroll to top of the page when navigating to a new page
    document.getElementById('root')?.scrollTo(0, 0);
  }, [currentSectionIndex]);

  if (submitted) {
    return (
      <Box mt={3} textAlign="center">
        <Box mb={2}>
          <Typography variant="h6">Thank you for completing the survey!</Typography>
        </Box>
        <Typography variant="body1">
          We have recorded your answers and will contact you with any questions.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <SectionHeader
        survey={survey}
        currentSectionIndex={currentSectionIndex}
        description={description}
      />

      <Formik<SurveyValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSectionSubmit}
        isInitialValid
      >
        {({ submitForm, isValid, isSubmitting, errors }) => {
          // This is to get this value from outside without using refs (they're not working for me)
          setIsFormikSubmitting(isSubmitting);

          const handleSubmit = () => {
            submitForm();

            if (!isValid && !isEmpty(errors)) {
              message.warning('The survey form is invalid. Please check the fields marked in red');
            }
          };

          return (
            <Form onSubmit={handleSubmit} onSubmitCapture={handleSubmit}>
              {sortedQuestions.map((q) => (
                <Fragment key={q.id}>
                  <SurveyQuestion question={q} />
                  <Divider />
                </Fragment>
              ))}
              {!isFirstSection && (
                <Box mb={2}>
                  <Button
                    onClick={() => setCurrentSectionIndex(currentSectionIndex - 1)}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={submittingForm || mustSubmit}
                  >
                    BACK
                  </Button>
                </Box>
              )}
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={submittingForm || mustSubmit}
              >
                {isLastSection ? 'SUBMIT SURVEY' : 'NEXT'}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SurveyForm;
