import noop from 'lodash/noop';
import { createContext } from 'react';
import { QuestionAnswer, Survey, SurveyQuestion } from '../domain/survey';

export interface SurveyContextData {
  survey: Survey;
  description: string;
  allQuestions: SurveyQuestion[];
  storedAnswers: Record<number, unknown>;
  questionAnswers: QuestionAnswer[];
  updateAnswer: (updatedAnswer: QuestionAnswer) => void;
  deleteQuestionAnswer: (questionId: number) => void;
}

export const SurveyContext = createContext<SurveyContextData>({
  survey: {} as Survey,
  description: '',
  allQuestions: [],
  storedAnswers: {},
  questionAnswers: [],
  updateAnswer: noop,
  deleteQuestionAnswer: noop,
});
