import * as Yup from 'yup';
import type { MixedSchema } from 'yup/lib/mixed';
import type { ImageFile } from '../domain/file';
import type {
  SurveyFile,
  SurveyQuestion,
  SurveyQuestionType,
  SurveyValues,
} from '../domain/survey';

export const getDefaultValueOfQuestion = (type: SurveyQuestionType) => {
  const defValues: Record<SurveyQuestionType, any> = {
    DATE: null,
    TEXT: '',
    IMAGES: [],
    RATING: '',
    YES_NO: '',
    OPTIONS: '',
  };

  return defValues[type];
};

export function getQuestionsInitialValues(
  questions: SurveyQuestion[],
  savedAnswers: SurveyValues | undefined,
  storedAnswers: Record<number, any>,
) {
  const initialValues: Record<string, any> = {};

  questions.forEach((q) => {
    initialValues[q.id] =
      storedAnswers[q.id] || savedAnswers?.[q.id] || getDefaultValueOfQuestion(q.type);
  });

  return initialValues;
}

export function getQuestionsValidationSchema(questions: SurveyQuestion[]) {
  const object: Record<string, any> = {};

  questions.forEach((q) => {
    let schema: MixedSchema;

    switch (q.type) {
      case 'TEXT':
      case 'YES_NO':
        schema = Yup.string();
        break;
      case 'DATE':
        schema = Yup.string().nullable();
        break;
      case 'RATING':
        schema = Yup.number();
        break;
      case 'IMAGES':
        schema = Yup.array();
        break;
      default:
        schema = Yup.string();
        break;
    }

    if (q.required) {
      let requiredSchema: MixedSchema;

      requiredSchema = schema.required('This field is required');

      if (q.type === 'IMAGES') {
        requiredSchema = (schema as Yup.ArraySchema<any>).min(1, 'This field is required');
      }

      if (q.enableIfQuestion) {
        schema = schema.when(String(q.enableIfQuestion.id), {
          is: (parentAnswer: unknown) => {
            const parentQuestion = questions.find(
              (question) => question.id === q.enableIfQuestion?.id,
            );

            const enablingAnswers =
              q.enableIfQuestion?.answerValue?.map((v) =>
                parseStoredQuestionValue(parentQuestion!, v),
              ) ?? [];

            return enablingAnswers.includes(parentAnswer);
          },
          then: requiredSchema,
          otherwise: schema,
        });
      } else {
        schema = requiredSchema;
      }
    }

    object[q.id] = schema;
  });

  return Yup.object(object);
}

export function parseValueToSurveyFile(value: ImageFile[] | string): SurveyFile[] {
  let files: ImageFile[] = [];

  if (typeof value === 'string') {
    files = JSON.parse(value as string);
  } else {
    files = value as ImageFile[];
  }

  return files.map((f) => ({
    id: f.id,
    description: '',
    file: { name: f.fileName || f.file?.name } as unknown as File,
  }));
}

// Convert answer from API response to runtime formik values
export function parseStoredQuestionValue(
  question: SurveyQuestion,
  value: unknown,
): string | number | SurveyFile[] | unknown {
  switch (question.type) {
    case 'RATING':
      return Number(value);
    case 'YES_NO':
      return value === true ? 'YES' : 'NO';
    case 'IMAGES':
      return parseValueToSurveyFile(value as ImageFile[] | string);
    default:
      return value;
  }
}

// Convert answer from formik runtime values to API values
export function unParseStoredQuestionValue(
  question: SurveyQuestion,
  value: unknown,
): number | unknown {
  switch (question.type) {
    case 'RATING':
      return Number(value);
    case 'YES_NO':
      if (value === 'YES') {
        return true;
      } else if (value === 'NO') {
        return false;
      } else {
        return '';
      }
    default:
      return value;
  }
}

export function shouldRenderQuestion(
  question: SurveyQuestion,
  parentQuestion: SurveyQuestion,
  values: SurveyValues,
): boolean {
  const parentValue = unParseStoredQuestionValue(parentQuestion, values[parentQuestion.id]);

  return question.enableIfQuestion?.answerValue?.includes(parentValue) ?? false;
}
