import { Field, useFormikContext } from 'formik';
import type { Moment } from 'moment';
import type { FC } from 'react';
import { useContext, useState } from 'react';
import DatePicker from '../../../components/common/DatePicker';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import { DATE_TIME_FORMAT } from '../../../utils/dates';
import { handleError } from '../../../utils/utils';

interface Props {
  question: SurveyQuestion;
}

const DateQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    errors,
    touched,
    setFieldValue,
    setSubmitting: setFormikSubmitting,
  } = useFormikContext<SurveyValues>();

  const error = errors[question.id];
  const isTouched = touched[question.id];

  const submitAnswer = (value: Moment) => {
    setSubmitting(true);
    setFormikSubmitting(true);
    SurveysService.postAnswer(question.id, value.format(DATE_TIME_FORMAT))
      .then(updateAnswer)
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: `There was an error saving answer for question ${question.rank}`,
        });
        setFieldValue(String(question.id), null); // TODO: Old value instead of null if already saved
      })
      .finally(() => {
        setSubmitting(false);
        setFormikSubmitting(false);
      });
  };

  return (
    <Field
      component={DatePicker}
      name={String(question.id)}
      fullWidth
      helperText={isTouched && error}
      size="small"
      afterChange={submitAnswer}
      disabled={submitting}
    />
  );
};

export default DateQuestion;
