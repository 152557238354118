import type { GetSurveyResponse, QuestionAnswer, SubmitResponse } from '../domain/survey';
import { api } from '../utils/configuration';
import { crudService } from './apiClient';

export const SurveysService = {
  getSurvey: () => crudService.get<GetSurveyResponse>(`${api.SURVEYS_URL}/answer-survey`),
  submit: () => crudService.post<SubmitResponse>({}, `${api.SURVEYS_URL}/answer-survey/submit`),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postAnswer: (questionId: number, value?: any, files: File[] = []) => {
    const formData = new FormData();

    formData.append('questionId', questionId.toString());

    if (value !== undefined) {
      formData.append('value', value);
    }

    files.forEach((file) => {
      formData.append('files', file);
    });

    return crudService.post<QuestionAnswer>(formData, `${api.SURVEYS_URL}/answer-survey/questions`);
  },
};
