import { useFormikContext } from 'formik';
import type { FC } from 'react';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { Grid, Typography } from '../../../utils/material';

interface Props {
  question: SurveyQuestion;
}

const QuestionLabel: FC<Props> = ({ question }) => {
  const { errors, touched } = useFormikContext<SurveyValues>();

  const withError = touched[question.id] && errors[question.id];

  return (
    <Grid container alignItems="flex-start" spacing={1}>
      <Grid item xs>
        <Typography variant="subtitle1" color={withError ? 'error' : 'initial'}>
          {question.text}{' '}
          {question.required && (
            <Typography component="span" color="error">
              *
            </Typography>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default QuestionLabel;
