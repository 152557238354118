import { Route, Switch } from 'react-router-dom';
import Page404 from '../components/404';
import SurveyPage from '../pages/surveys/survey';

const Routes = () => {
  return (
    <Switch>
      <Route path="/surveys">
        <SurveyPage />
      </Route>

      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default Routes;
