import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios';
import { getAccessToken } from '../utils/auth';
import { convertObjectToQueryString } from '../utils/configuration';
import message from '../utils/toast';

export class ResponseError extends Error {
  response?: AxiosResponse;

  jsonResponse: any;

  textResponse: string;

  displayMessage: string;

  status?: number;

  constructor(msg: string, response?: AxiosResponse) {
    super(msg);
    this.response = response;
    this.jsonResponse = {};
    this.textResponse = '';
    this.displayMessage = msg;
  }
}

const apiClient = Axios.create({
  baseURL: '/rest',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (params) => convertObjectToQueryString(params, false),
});

apiClient.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

const errorInterceptor = (error: AxiosError) => {
  let errorText = `Unhandled status: ${error.response?.status}`;
  if (error.response?.status === 401) {
    errorText = 'The username or password you entered is incorrect.';
  }

  if (error.response?.status === 500) {
    errorText = 'Internal Server Error';
  }

  if (error.response?.status === 400) {
    errorText = 'Bad Request';
  }

  if (error.response?.status === 504) {
    errorText = 'Gateway timeout';
    message.error('Error communicating with remote server. Please check your internet connection.');
  }

  if (Axios.isCancel(error)) {
    return {};
  }

  const responseError = new ResponseError(errorText, error.response);

  responseError.status = error.response?.status;

  if (error.response?.data instanceof Object) {
    responseError.jsonResponse = error.response!.data;
    if (responseError.jsonResponse.message) {
      responseError.displayMessage = responseError.jsonResponse.message;
    } else if (responseError.jsonResponse.errors) {
      [responseError.displayMessage] = responseError.jsonResponse.errors;
    }
  } else {
    responseError.textResponse = error.response?.data;
  }

  throw responseError;
};

apiClient.interceptors.response.use((response) => response, errorInterceptor);

export const crudService = {
  get: <T>(url: string, params?: AxiosRequestConfig) =>
    apiClient.get<T, AxiosResponse<T>>(url, params).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: <T>(obj: any, url: string, params?: AxiosRequestConfig) =>
    apiClient.post<T, AxiosResponse<T>>(url, obj, params).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: <T>(obj: any, url: string) =>
    apiClient.put<T, AxiosResponse<T>>(url, obj).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch: <T>(obj: any, url: string) =>
    apiClient.patch<T, AxiosResponse<T>>(url, obj).then((response) => response.data),

  delete: <T>(url: string) =>
    apiClient.delete<T, AxiosResponse<T>>(url).then((response) => response.data),
};
