import { useEffect, useMemo, useState } from 'react';
import { SurveyContext } from '../../contexts/SurveyContext';
import type { QuestionAnswer, Survey } from '../../domain/survey';
import { useQuery } from '../../hooks/useQuery';
import { SurveysService } from '../../services/surveys';
import { setAccessToken } from '../../utils/auth';
import {
  Alert,
  AppBar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '../../utils/material';
import { parseStoredQuestionValue } from '../../utils/survey';
import { handleError } from '../../utils/utils';
import SurveyForm from './components/SurveyForm';

const SurveyPage = () => {
  const [survey, setSurvey] = useState<Survey>();
  const [description, setDescription] = useState('');
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([]);
  const [loadingSurvey, setLoadingSurvey] = useState(true);
  const [error, setError] = useState(false);
  const query = useQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const jwt = query.get('jwt') || '';

  const initSurvey = () => {
    setAccessToken(jwt);
    setLoadingSurvey(true);
    SurveysService.getSurvey()
      .then((res) => {
        setSurvey(res.survey);
        setDescription(res.answer?.description || '');
        setQuestionAnswers(res.answer?.questionAnswers || []);
      })
      .catch((err) => {
        handleError(err, {
          displayToast: false,
          rethrowError: false,
          sendToSentry: true,
        });
        setError(true);
      })
      .finally(() => {
        setLoadingSurvey(false);
      });
  };

  useEffect(() => {
    initSurvey();
  }, [jwt]);

  const allQuestions = useMemo(() => {
    return (survey?.sections?.map((s) => s.questions) ?? []).flat();
  }, [survey]);

  const storedAnswers = useMemo(() => {
    const result: Record<number, unknown> = {};

    questionAnswers.forEach((questionAnswer) => {
      const question = allQuestions.find((q) => q.id === questionAnswer.questionId);

      if (!question) {
        return;
      }

      result[questionAnswer.questionId] = parseStoredQuestionValue(question, questionAnswer.value);
    });

    return result;
  }, [questionAnswers, allQuestions]);

  const updateAnswer = (updatedAnswer: QuestionAnswer) => {
    const currentAnswerIndex = questionAnswers.findIndex(
      (a) => a.questionId === updatedAnswer.questionId,
    );

    const updatedQuestionAnswers = [...questionAnswers];

    if (currentAnswerIndex === -1) {
      updatedQuestionAnswers.push(updatedAnswer);
    } else {
      updatedQuestionAnswers.splice(currentAnswerIndex, 1, updatedAnswer);
    }

    setQuestionAnswers(updatedQuestionAnswers);
  };

  const deleteQuestionAnswer = (questionId: number) => {
    const currentAnswerIndex = questionAnswers.findIndex((a) => a.questionId === questionId);
    const updatedQuestionAnswers = [...questionAnswers];

    if (currentAnswerIndex !== -1) {
      updatedQuestionAnswers.splice(currentAnswerIndex, 1);
    }

    setQuestionAnswers(updatedQuestionAnswers);
  };

  if (loadingSurvey) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={24}
        py={8}
      >
        <CircularProgress />
        <Typography>Loading survey...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={8}>
        <Alert style={{ width: 300 }} severity="error">
          Link expired or malformed
        </Alert>
      </Box>
    );
  }

  if (!survey) {
    return null;
  }

  return (
    <SurveyContext.Provider
      value={{
        survey,
        description,
        questionAnswers,
        allQuestions,
        storedAnswers,
        updateAnswer,
        deleteQuestionAnswer,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">{survey.name} Survey</Typography>
        </Toolbar>
      </AppBar>
      <Box my={isMobile ? 0 : 8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Paper>
              <Box pt={1} pb={4} px={3}>
                <SurveyForm />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </SurveyContext.Provider>
  );
};

export default SurveyPage;
