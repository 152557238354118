import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import { handleError } from '../../../utils/utils';

interface Props {
  question: SurveyQuestion;
}

const TextQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setSubmitting: setFormikSubmitting,
  } = useFormikContext<SurveyValues>();

  const error = errors[question.id];
  const isTouched = touched[question.id];

  const submitAnswer = (e: ChangeEvent<HTMLInputElement>) => {
    handleBlur(e);
    const { value } = e.target;

    setSubmitting(true);
    setFormikSubmitting(true);
    SurveysService.postAnswer(question.id, value)
      .then(updateAnswer)
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: `There was an error saving answer for question ${question.rank}`,
        });
        setFieldValue(String(question.id), ''); // TODO: Old value instead of empty if already saved
      })
      .finally(() => {
        setSubmitting(false);
        setFormikSubmitting(false);
      });
  };

  return (
    <Field
      component={TextField}
      name={String(question.id)}
      fullWidth
      helperText={isTouched && error}
      size="small"
      onBlur={submitAnswer}
      disabled={submitting}
    />
  );
};

export default TextQuestion;
