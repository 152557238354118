import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion as SurveyQuestionType, SurveyValues } from '../../../domain/survey';
import { Alert, Box } from '../../../utils/material';
import { getDefaultValueOfQuestion, shouldRenderQuestion } from '../../../utils/survey';
import DateQuestion from './DateQuestion';
import FileQuestion from './FilesQuestion';
import OptionsQuestion from './OptionQuestion';
import QuestionLabel from './QuestionLabel';
import RatingQuestion from './RatingQuestion';
import TextQuestion from './TextQuestion';
import YesNoQuestion from './YesNoQuestion';

interface Props {
  question: SurveyQuestionType;
}

const SurveyQuestion: FC<Props> = ({ question }) => {
  const { values, setFieldValue } = useFormikContext<SurveyValues>();
  const { allQuestions, deleteQuestionAnswer } = useContext(SurveyContext);

  const value = values[question.id];

  const input = useMemo(() => {
    switch (question.type) {
      case 'TEXT':
        return <TextQuestion question={question} />;
      case 'DATE':
        return <DateQuestion question={question} />;
      case 'YES_NO':
        return <YesNoQuestion question={question} />;
      case 'RATING':
        return <RatingQuestion question={question} />;
      case 'OPTIONS':
        return <OptionsQuestion question={question} />;
      case 'IMAGES':
        return <FileQuestion question={question} />;
      default:
        return <Alert severity="warning">Unknown question type</Alert>;
    }
  }, [question]);

  const showQuestion: boolean = useMemo(() => {
    if (!question.enableIfQuestion) {
      return true;
    }

    const parentQuestion = allQuestions.find((q) => q.id === question.enableIfQuestion?.id);

    if (!parentQuestion) {
      // This should never happen
      return false;
    }

    return shouldRenderQuestion(question, parentQuestion, values);
  }, [question, values]);

  useEffect(() => {
    const defaultValue = getDefaultValueOfQuestion(question.type);

    if (!showQuestion) {
      if (question.type === 'IMAGES') {
        if (value?.length) {
          setFieldValue(String(question.id), []);
          deleteQuestionAnswer(question.id);
        }
      } else if (value !== defaultValue) {
        setFieldValue(String(question.id), defaultValue);
      }
    }
  }, [showQuestion, value]);

  if (!showQuestion) {
    return null;
  }

  return (
    <Box mt={4} mb={5}>
      <QuestionLabel question={question} />
      <Box px={5} mt={2}>
        {input}
      </Box>
    </Box>
  );
};

export default SurveyQuestion;
