import type { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, makeStyles, PublishIcon, Typography } from '../../utils/material';

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    cursor: 'pointer',
    border: '2px dashed rgba(16, 32, 39, 0.16)',
    borderRadius: 4,
    height: 140,
    width: '100%',

    '&.active': {
      borderColor: theme.palette.primary.main,
    },
  },
  uploadIcon: {
    color: theme.palette.primary.main,
    fontSize: 40,
    marginBottom: 4,
  },
}));

interface Props {
  onDrop: (acceptedPhotos: File[]) => void;
  accept?: string;
  multiple?: boolean;
}

const DropFile: FC<Props> = ({ onDrop, accept, multiple }) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <Box
      {...getRootProps()}
      className={`${classes.dropContainer} ${isDragActive ? 'active' : ''}`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <input {...getInputProps()} />
      <PublishIcon className={classes.uploadIcon} />
      <Typography variant="body2">Click to upload photos...</Typography>
    </Box>
  );
};

export default DropFile;
