import type { FC } from 'react';
import type { Survey } from '../../../domain/survey';
import { Box, Chip, Divider, Typography } from '../../../utils/material';

interface Props {
  survey: Survey;
  currentSectionIndex: number;
  description: string;
}

const SectionHeader: FC<Props> = ({ survey, currentSectionIndex, description }) => {
  const section = survey.sections[currentSectionIndex];

  return (
    <>
      <Box my={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="textPrimary">
            {section.title}
          </Typography>
          <Chip label={`${currentSectionIndex + 1} / ${survey.sections.length}`} />
        </Box>

        {description ? (
          <Box whiteSpace="pre-line" my={1}>
            <Typography variant="subtitle2" color="textPrimary">
              {description}
            </Typography>
          </Box>
        ) : null}

        {section.subheading && (
          <Box mt={1}>
            <Typography variant="subtitle1" color="textPrimary">
              {section.subheading}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default SectionHeader;
