import MomentUtils from '@date-io/moment';
import type { FieldInputProps, FormikProps } from 'formik';
import type { FC } from 'react';
import type { DatePickerProps } from '../../utils/material';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '../../utils/material';

interface Props extends DatePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: FieldInputProps<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormikProps<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterChange: (date: any) => void;
}

const DatePicker: FC<Props> = ({ field, form, afterChange, ...props }) => {
  const currentError = form.errors[field.name];
  const isTouched = form.touched[field.name];
  const format = 'yyyy-MM-DD';

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          clearable
          name={field.name}
          format={format}
          helperText={currentError}
          error={isTouched && Boolean(currentError)}
          {...props}
          value={field.value}
          onChange={(date) => {
            form.setFieldValue(field.name, date?.format(format), true);
            afterChange(date);
          }}
          label={props.label}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DatePicker;
