import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { FileValue, SurveyFile, SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import {
  Box,
  Button,
  CircularProgress,
  DeleteIcon,
  IconButton,
  Typography,
} from '../../../utils/material';
import { parseValueToSurveyFile } from '../../../utils/survey';
import { handleError } from '../../../utils/utils';
import AddFileModal from './AddFileModal';
import ErrorMessage from './ErrorMessage';

interface Props {
  question: SurveyQuestion;
}

const FileQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { values, setFieldValue, setSubmitting } = useFormikContext<SurveyValues>();

  const value: SurveyFile[] = values[question.id];

  const handleNewFile = (file: File, description: string) => {
    setShowModal(false);
    setUploading(true);
    setSubmitting(true);

    const currentValues: FileValue[] = value.map((v) => ({
      id: v.id,
      fileName: v.file.name,
      description: v.description,
    }));
    const fileValue: FileValue = { fileName: file.name, description };

    SurveysService.postAnswer(question.id, JSON.stringify([...currentValues, fileValue]), [file])
      .then((res) => {
        const newValue: SurveyFile[] = parseValueToSurveyFile(res.value);
        setFieldValue(question.id.toString(), newValue);
        updateAnswer(res);
      })
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: 'There was an error uploading the file',
        });
      })
      .finally(() => {
        setUploading(false);
        setSubmitting(false);
      });
  };

  const handleFileDelete = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);

    setUploading(true);
    setSubmitting(true);
    SurveysService.postAnswer(question.id, JSON.stringify(newValue))
      .then((res) => {
        setFieldValue(question.id.toString(), newValue);
        updateAnswer(res);
      })
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: 'There was an error deleting the file',
        });
      })
      .finally(() => {
        setUploading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Button
        style={{ width: 100 }}
        variant="contained"
        color="primary"
        disabled={uploading}
        onClick={() => setShowModal(true)}
      >
        {uploading ? <CircularProgress size={24} color="inherit" /> : 'Browse'}
      </Button>
      <Box mt={3}>
        {value?.map((f, index) => (
          <Box key={f.file.size} mb={1}>
            <Typography color="primary" variant="subtitle1" component="span">
              {f.file.name}
            </Typography>
            <IconButton
              size="small"
              color="inherit"
              style={{ marginLeft: 8 }}
              onClick={() => handleFileDelete(index)}
              disabled={uploading}
            >
              <DeleteIcon color={uploading ? 'disabled' : 'error'} />
            </IconButton>
          </Box>
        ))}
      </Box>
      <ErrorMessage name={String(question.id)} />
      {showModal && (
        <AddFileModal open onCancel={() => setShowModal(false)} onSuccess={handleNewFile} />
      )}
    </>
  );
};

export default FileQuestion;
