import { useFormikContext } from 'formik';
import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../contexts/SurveyContext';
import type { SurveyQuestion, SurveyValues } from '../../../domain/survey';
import { SurveysService } from '../../../services/surveys';
import {
  Box,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '../../../utils/material';
import { handleError } from '../../../utils/utils';
import ErrorMessage from './ErrorMessage';

interface Props {
  question: SurveyQuestion;
}

const useStyles = makeStyles<null, { isMobile: boolean }>({
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 14,
  },
  optionsContainer: {
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
    justifyContent: 'space-between',
  },
});

const RatingQuestion: FC<Props> = ({ question }) => {
  const { updateAnswer } = useContext(SurveyContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    values,
    setFieldValue,
    setSubmitting: setFormikSubmitting,
  } = useFormikContext<SurveyValues>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const getLabel = (value: number) => {
    if (isMobile && value === 1) {
      return (
        <>
          1{' '}
          <Typography component="span" variant="caption" color="textSecondary">
            (Poor)
          </Typography>
        </>
      );
    }

    if (isMobile && value === 5) {
      return (
        <>
          5{' '}
          <Typography component="span" variant="caption" color="textSecondary">
            (Excellent)
          </Typography>
        </>
      );
    }

    return value;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue(String(question.id), value);

    setSubmitting(true);
    setFormikSubmitting(true);
    SurveysService.postAnswer(question.id, value)
      .then(updateAnswer)
      .catch((err) => {
        handleError(err, {
          displayToast: true,
          rethrowError: false,
          sendToSentry: true,
          toastMessage: `There was an error saving answer for question ${question.rank}`,
        });
        setFieldValue(String(question.id), undefined); // TODO: Old value instead of undefined string if already saved
      })
      .finally(() => {
        setSubmitting(false);
        setFormikSubmitting(false);
      });
  };

  const value = Number(values[question.id]);

  return (
    <>
      {!isMobile && (
        <Box mb={1} className={classes.topContainer}>
          <Typography variant="caption" color="textSecondary">
            Poor
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Excellent
          </Typography>
        </Box>
      )}
      <RadioGroup value={value} onChange={handleChange} className={classes.optionsContainer}>
        {[1, 2, 3, 4, 5].map((v) => (
          <FormControlLabel
            key={v}
            value={v}
            control={<Radio color="primary" disabled={submitting} />}
            label={getLabel(v)}
            disabled={submitting}
          />
        ))}
      </RadioGroup>
      <ErrorMessage name={String(question.id)} />
    </>
  );
};

export default RatingQuestion;
